<template>
  <el-col id="doc">
    <h1>{{$t('road-map')}}</h1>
  </el-col>
</template>

<script>
export default {
  name: "RoadMap",
};
</script>
<style scoped>
.el-col {
  padding: 30px;
}
</style>